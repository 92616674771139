<template>
  <div>
    <CRow>
      <CCol>
        <RAsyncPagedTable
          :data="data.results"
          :columns="columns"
          :page="page"
          :page-size="pageSize"
          :total-records="data.count"
          @fetch="$emit('fetch')"
          @page-change="$emit('page-change', $event)"
        >
          <template #timestamp="{ item }">
            <td>
              <b>{{ item.timestamp | formatDateTime('MMM d, yyyy') }}</b>
              <span class="text-center"> at {{ item.timestamp | formatDateTime('h:mm a') }}</span>
            </td>
          </template>
          <template #answered="{ item }">
            <td class="text-left">
              <CIcon
                :name="item.answered ? 'md-phone-callback' : 'md-phone-missed'"
                :class="item.answered ? 'answered' : 'unanswered'"
              />
            </td>
          </template>
          <template #duration="{ item }">
            <td class="text-left">
              {{ item.duration | formatDuration('s', '-') }}
            </td>
          </template>
          <template #phone_contact="{ item }">
            <td class="phone-contact">
              <a :href="`tel:${item.phone_contact}`">{{ item.phone_contact }}</a>
            </td>
          </template>
        </RAsyncPagedTable>
      </CCol>
    </CRow>
  </div>
</template>

<script>
/**
 * Wrapper component for a PhoneCall-specific implementation of RAsyncPageTable.
 *
 * Events:
 * @fetch: triggered when component state changes occur that require (re-)populating the data.
 * @page-change: triggered when the selected page or pageSize are changed.
 */
import { RAsyncPagedTable } from '@/components/tables'

const columns = [
  {
    key: 'timestamp',
    label: 'Date'
  },
  {
    key: 'answered'
  },
  {
    key: 'duration'
  },
  {
    key: 'phone_tracking',
    label: 'Tracking Number'
  },
  {
    key: 'contact_information'
  },
  {
    key: 'phone_contact',
    label: 'Contact Number'
  }
]

export default {
  name: 'PhoneSummaryTable',
  components: {
    RAsyncPagedTable
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      columns
    }
  }
}
</script>

<style lang="scss" scoped>
  .c-icon {
    &.answered {
      color: $success;
    }
    &.unanswered {
      color: $danger;
    }
  }
  td.phone-contact {
    color: $info;
    font-weight: 600;
    a:hover {
      text-decoration: none;
    }
  }
</style>
