<template>
  <CRow class="widgets">
    <CCol>
      <ReportingWidget
        title="Calls Answered"
        :text="widgetData.percentAnswered"
        icon="md-phone-callback"
        info-text="Number of calls answered by the property in the selected time span."
      />
    </CCol>
    <CCol>
      <ReportingWidget
        title="Average Duration"
        :text="widgetData.averageDuration"
        icon="md-sync"
        info-text="Average duration of calls within the selected time span."
      />
    </CCol>
    <CCol>
      <ReportingWidget
        title="30+ Second Calls"
        :text="widgetData.countThirtySeconds"
        icon="md-timer"
        info-text="Calls that were longer than 30 seconds within the selected time span."
      />
    </CCol>
    <CCol>
      <ReportingWidget
        title="Unique Callers"
        :text="widgetData.countUnique"
        icon="md-people"
        info-text="Unique people who placed a call within the selected time span."
      />
    </CCol>
  </CRow>
</template>

<script>
import { formatDuration } from '@/plugins/filters'
import { isNumeric } from '@/utils'
import { ReportingWidget } from '@/views/reporting/_components'

export default {
  name: 'Widgets',
  components: {
    ReportingWidget
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    widgetData () {
      const formattedDuration = formatDuration(this.data.average_duration, 's', '0s')
      return {
        averageDuration: isNumeric(this.data.average_duration) ? formattedDuration : null,
        countThirtySeconds: isNumeric(this.data.count_thirty_seconds) ? `${this.data.count_thirty_seconds}` : null,
        countUnique: isNumeric(this.data.count_unique) ? `${this.data.count_unique}` : null,
        percentAnswered: isNumeric(this.data.percent_answered) ? `${this.data.percent_answered}%` : null
      }
    }
  }
}
</script>
