var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',[_c('RAsyncPagedTable',{attrs:{"data":_vm.data.results,"columns":_vm.columns,"page":_vm.page,"page-size":_vm.pageSize,"total-records":_vm.data.count},on:{"fetch":function($event){return _vm.$emit('fetch')},"page-change":function($event){return _vm.$emit('page-change', $event)}},scopedSlots:_vm._u([{key:"timestamp",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('b',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.timestamp,'MMM d, yyyy')))]),_c('span',{staticClass:"text-center"},[_vm._v(" at "+_vm._s(_vm._f("formatDateTime")(item.timestamp,'h:mm a')))])])]}},{key:"answered",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-left"},[_c('CIcon',{class:item.answered ? 'answered' : 'unanswered',attrs:{"name":item.answered ? 'md-phone-callback' : 'md-phone-missed'}})],1)]}},{key:"duration",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm._f("formatDuration")(item.duration,'s', '-'))+" ")])]}},{key:"phone_contact",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"phone-contact"},[_c('a',{attrs:{"href":("tel:" + (item.phone_contact))}},[_vm._v(_vm._s(item.phone_contact))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }