<template>
  <div id="phone-report">
    <CRow>
      <CCol>
        <Widgets :data="data.metrics" />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <RTableFilterOptions :config="filterConfig" @change="handleFilterChange" />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <PhoneSummaryTable
          :data="data"
          :page="page"
          :page-size="pageSize"
          :loading="loading"
          @fetch="fetchPhoneCallReport"
          @page-change="onPageChange"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { formatISO } from 'date-fns'

import { RTableFilterOptions } from '@/components/tables'
import { paginatedRequest } from '@/services/api'
import { ReportingAPI } from '@/services/api/resources'

import PhoneSummaryTable from './PhoneSummaryTable'
import Widgets from './Widgets'

export default {
  name: 'PhoneReport',
  components: {
    PhoneSummaryTable,
    RTableFilterOptions,
    Widgets
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      data: {
        results: [],
        count: 0,
        page: this.page,
        page_size: this.pageSize,
        metrics: {
          average_duration: null,
          count_thirty_seconds: null,
          count_unique_caller: null,
          percent_answered: null
        }
      },
      filters: {
        answered: [],
        search: ''
      },
      filterConfig: {
        answered: {
          type: 'multiselect',
          label: 'Answered',
          options: [
            {
              label: 'Yes',
              value: true
            },
            {
              label: 'No',
              value: false
            }
          ]
        },
        search: {
          type: 'input',
          label: 'Search Contacts'
        }
      },
      page: 1,
      pageSize: 50
    }
  },
  watch: {
    dateRange: {
      handler: function () {
        this.page = 1
        this.fetchPhoneCallReport()
      }
    }
  },
  async created () {
    await this.fetchPhoneCallReport()

    if (this.data.results.length === 0) {
      this.$emit('no-data')
    }
  },
  methods: {
    /**
     * Get params for the report
     */
    getParams () {
      const params = new URLSearchParams()
      params.append('from_date', formatISO(this.dateRange.start))
      params.append('to_date', formatISO(this.dateRange.end))
      params.append('tz', this.dateRange.tz)
      params.append('page', this.page)
      params.append('page_size', this.pageSize)
      if (this.filters.answered && this.filters.answered.length === 1) {
        params.append('answered', this.filters.answered)
      }
      if (this.filters.search) {
        params.append('q', this.filters.search)
      }
      return params
    },
    /**
     * Fetch the property phone call report.
     */
    async fetchPhoneCallReport () {
      this.$emit('loading', true)

      const params = this.getParams()
      params.append('page_size', this.pageSize)

      const response = await ReportingAPI.property.calls({
        id: this.$route.params.pid,
        params
      })
      if (response) {
        this.data = response.data
      }
      this.$emit('loading', false)
    },
    handleFilterChange (filters) {
      this.filters = filters
      this.fetchPhoneCallReport()
    },
    onPageChange (pageData) {
      this.page = pageData.page
      this.pageSize = pageData.pageSize
      this.fetchPhoneCallReport()
    },
    async exportData () {
      const params = this.getParams()
      this.$emit('loading', true)
      const response = await paginatedRequest(ReportingAPI.property.calls, this.$route.params.pid, params)
      this.$emit('loading', false)
      return response.data
    },
    /**
     * onRefresh handler called by BaseReport component.
     */
    refresh () {
      this.fetchPhoneCallReport()
    }
  }
}
</script>
